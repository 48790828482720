import React from "react";
import { Grid } from '@material-ui/core';
import {
  EntityAboutCard,
  EntityLayout,
  RelatedEntitiesCard,
} from '@backstage/plugin-catalog';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  Entity,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import { EaPackSystemEntityV1beta1 } from "@internal/plugin-ea-pack-common";
import { EntityTable } from "@backstage/plugin-catalog-react";
import { TableColumn } from "@backstage/core-components";


const asSystemEntities = (entities: Entity[]): EaPackSystemEntityV1beta1[] => entities as EaPackSystemEntityV1beta1[];
const systemEntityColumns: TableColumn<EaPackSystemEntityV1beta1>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: "System" }),
  EntityTable.columns.createEntityRelationColumn({ title: "Sub-domain", relation: "partOf", defaultKind: "domain", filter: { kind: "domain" } }),
  EntityTable.columns.createMetadataDescriptionColumn(),
];

export const BusinessCapabilityEntityLayout = () => (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard
            variant="gridItem"
            direction={Direction.BOTTOM_TOP}
            title="Business Capability Map"
            height={300}
            relations={[
              RELATION_PARENT_OF,
            ]}
            unidirectional={false}
            maxDepth={3}
          />
        </Grid>
        <Grid item md={12}>
          <RelatedEntitiesCard
            variant="gridItem"
            title="Products supporting business capability"
            entityKind={"System"}
            relationType={"deliveredBy"}
            columns={systemEntityColumns}
            asRenderableEntities={asSystemEntities}
            emptyMessage="No Products supporting business capability"
            emptyHelpLink="https://backstage.io/docs/features/catalog/descriptor-format"
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);