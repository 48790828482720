import { Chip, Grid, List, ListItem, Paper, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/frontend-plugin-api';
import { ContributionEntityV1beta1 } from '@internal/plugin-ea-pack-common';
import { UserEntity } from '@backstage/catalog-model';
import { Avatar, Link, Table } from '@backstage/core-components';
import RefreshIcon from '@material-ui/icons/Refresh';
import CodeIcon from '@material-ui/icons/Link';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { PandoraRed, PandoraYellow } from '@internal/plugin-pandora-react';


type GraphQLResponse = {
    data: {
        actor: {
            nrql: {
                results: Array<LogEntry>
            }
        }
    }
}
type LogEntry = {
    level: "debug" | "info" | "warn" | "error",
    location?: string
    message: string
    timestamp: number,
    date: Date
}



export const CatalogLogsPage = () => {
    const discoApi = useApi(discoveryApiRef);
    var fetchApi = useApi(fetchApiRef);

    const [logs, setLogs] = useState<GraphQLResponse | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const baseUrl = await discoApi.getBaseUrl('newrelic-logs');
            const response = await fetchApi.fetch(`${baseUrl}/logs/catalog?since=${60}`);
            if (response.ok) {
                const data = await response.json() as GraphQLResponse;

                data.data.actor.nrql.results.forEach(log => { log.date = new Date(log.timestamp) });

                setLogs(data);
            }
        })();
    }, [discoApi, fetchApi]);


    return (
        <Table<LogEntry>
            columns={[
                {
                    title: 'Timestamp',
                    field: 'date',
                    cellStyle: { whiteSpace: "nowrap", paddingTop: 4, paddingBottom: 4 },
                    type: 'datetime',
                    width: "15%",
                    render: (row) => row.date.toISOString()
                },
                {
                    title: 'Level',
                    field: 'level',
                    type: 'string',
                    width: '10%',
                    cellStyle: { paddingTop: 4, paddingBottom: 4 },
                    render: (row) => (<code>{row.level}</code>)
                },
                {
                    title: 'Message',
                    field: 'message',
                    type: 'string',
                    cellStyle: { paddingTop: 4, paddingBottom: 4 },
                    render: (row) => (row.location ? <Link to={row.location.startsWith("url:") ? row.location.substring(4) : row.location} target='_blank'>{row.message}</Link> : row.message)
                },
            ]}
            data={logs?.data.actor.nrql.results || []}
            isLoading={!logs}
            options={{
                paging: true,
                search: true,
                pageSize: 100,
                rowStyle: (data) => {
                    switch (data.level) {
                        case 'error':
                            return { backgroundColor: PandoraRed };
                        case 'warn':
                            return { backgroundColor: PandoraYellow };
                        default:
                            return {};
                    }
                }
            }}
            emptyContent="No logs found"
            filters={[{ column: 'Level', type: "multiple-select" }]}
            actions={[{
                icon: RefreshIcon,
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: async () => {
                    setLogs(undefined);
                    const baseUrl = await discoApi.getBaseUrl('newrelic-logs');
                    const response = await fetchApi.fetch(`${baseUrl}/logs/catalog?since=${60}`);
                    if (response.ok) {
                        const data = await response.json() as GraphQLResponse;

                        data.data.actor.nrql.results.forEach(log => { log.date = new Date(log.timestamp) });

                        setLogs(data);
                    }
                }
            }]}
        />
    );
};